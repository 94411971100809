<template>
  <div class="bonus-card">
    <div>
      <div class="text-content">
        <h4 class="bonus-value" v-html="formattedBonus"></h4>
        <p class="description">{{ descricao }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nivel: {
      type: Number,
      required: true,
    },
    bonus: {
      type: Number,
      default: 0,
    },
    descricao: {
      type: String,
      required: true,
    },
    isNumeric: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedBonus() {
      if (this.isNumeric) {
        return this.bonus || 0;
      } else {
        const value = this.bonus || 0;
        const formattedValue = value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return `<span class="icon-positive">+</span> ${formattedValue}<span class="currency"> USDT</span>`;
      }
    },
  },
};
</script>

<style scoped>
/* Ajuste para tema claro */
.bonus-card {
  background-color: #ffffff; /* Fundo branco */
  border-left: 5px solid #58BDE9;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Leve sombra */
}

/* Estilo no hover */
.bonus-card:hover {
  background-color: #f9f9f9; /* Clareamento leve no hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Sombra um pouco mais intensa */
}

/* Centralização do conteúdo */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text-content {
  text-align: left;
}

/* Valor do bônus */
.bonus-value {
  font-weight: bold;
  font-size: 1.6rem;
  margin: 0;
  color: #333333; /* Texto escuro para contraste com fundo claro */
}

/* Descrição */
.description {
  font-size: 1rem;
  color: #555555; /* Texto mais suave */
  margin: 0;
  margin-top: 0.5rem;
}

/* Ícone positivo */
.icon-positive {
  color: #26C86E;
  font-size: 1.3rem;
}

/* Formato da moeda */
.currency {
  font-size: 0.9rem;
  color: #333333; /* Cor da moeda alinhada com o texto */
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .bonus-card {
    padding: 0.8rem;
  }

  .bonus-value {
    font-size: 1.4rem;
  }

  .description {
    font-size: 0.95rem;
  }
}
</style>
